import './Game.scss';

import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

const Game = ({word, resetWord, wordsRemaining, clearBoard, extraLetters, gameDisabled}) => {
    const [guess, setGuess] = useState('');
    const [incorrect, setIncorrect] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const [showHint, setShowHint] = useState(false);
    const [incorrectGuesses, setIncorrectGuesses] = useState(0);
    const [gaveUp, setGaveUp] = useState(false);

    useEffect(() => {
        if (incorrect) {
            setTimeout(() => setIncorrect(false), 1000);
        }

    }, [incorrect])

    const handleGuess = (event) => {
        event.preventDefault();
        setMessage('');
        if (guess.toLowerCase() === word.toLowerCase()) {
            confetti({particleCount: 250, spread: 200});
            setSuccess(true);
            clearBoard();
        } else {
            setIncorrect(true);
            setIncorrectGuesses(prevValue => prevValue + 1);
            if (closeMatch(guess, word.toLowerCase())) setMessage('very close...')
        }
    }

    const reset = () => {
        resetWord();
        setGuess('');
        setMessage('');
        setSuccess(false);
        setIncorrectGuesses(0);
        setShowHint(false);
        setGaveUp(false);
    };

    return (
        <Box id="game" component="section" style={{padding: '4rem', alignItems: 'center', fontSize: '1.5rem', minHeight: '14rem'}}>
            <Typography color="primary.main" sx={{fontSize: '3rem', fontWeight: '600', textAlign: 'center'}}>Five of the seven letters hidden above make a word &#8211; can you guess it?</Typography>
            {gameDisabled ? <p style={{textAlign: 'center'}}>Please refresh the page to enable game.</p> : 
            success || gaveUp ? 
             <div id='guess-button' style={{display: 'flex', gridGap: '1rem', justifyContent: 'center', alignItems: 'baseline', marginTop: '1rem'}}>
                {gaveUp ? 
                    <p>The word was {word}!! {wordsRemaining ? 'Better luck next time :)' : 'Thanks for playing :)'}</p>
                :
                    <p>{wordsRemaining ? 'You did it!!' : 'You guessed all the words configured! Thanks for playing :)'}</p>
                }
                {wordsRemaining ? <Button sx={{height: '4rem', fontSize: '1.5rem'}} onClick={reset} variant="contained">Play again</Button> : undefined}
            </div>
            :
            <div>
            <div style={{textAlign: 'center'}}>
            <form onSubmit={handleGuess} style={{display: 'flex', gridGap: '1rem', justifyContent: 'center', alignItems: 'baseline', marginTop: '1rem'}}>
                <TextField InputProps={{sx: {fontSize: '1.5rem'}}} error={incorrect} className={incorrect ? 'error' : ''} helperText={message} value={guess} onChange={event => {setMessage(''); setGuess(event.target.value);}} variant="outlined"/>
                <Button sx={{height: '4rem', fontSize: '1.5rem'}} type="submit" variant="contained">Submit Guess</Button>
            </form>
            <Button sx={{fontSize: '1.25rem'}} onClick={setGaveUp.bind({}, true)}>Give up</Button>
            {incorrectGuesses > 0 && !showHint ? <Button sx={{fontSize: '1.25rem'}} onClick={setShowHint.bind({}, true)}>Hint</Button> : undefined}
            {showHint ? <p style={{marginBottom: '0'}}>The unused letters are {extraLetters.join(' and ')}</p> : undefined}
            </div>
            </div>
            }
        </Box>
    );
};

const closeMatch = (guess, word) => {
    // calculate letters in right place
    const wordLength = word.length;
    let correctLetters = 0;
    for (const [index, char] of [...guess].entries()) {
        if (word[index].toLowerCase() === char.toLowerCase()) {
            correctLetters++;
        }
    }
    return (Math.abs(correctLetters - wordLength) <= 1);
}

export default Game;