import './Projects.scss';
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import StreamingWatchlist from './streaming_watchlist.png';
import Recipes from './recipes.jpeg';
import Funglish from './funglish.png';
import ProjectModal from './ProjectModal';
import { useState } from 'react';

const projects = [
    {
        id: 'watchlist',
        title: 'Streaming Watchlist',
        image: StreamingWatchlist,
        video: 'watchlist.mp4',
        imageAlt: 'Streaming Watchlist Chrome extension',
        desc: `A Chrome extension that manages a "watchlist" across different streaming services including Netflix, Hulu, Amazon Prime, and Disney+. \
                Users can open the extension while on a selected movie or show and scrubs title and description from the page to add to data.`,
        technologies: ['Javascript', 'React', 'Chrome Developers'],
    },
    {
        id: 'recipes',
        title: 'Family Recipes',
        image: Recipes,
        video: 'recipes.mp4',
        imageAlt: 'Family Recipes App',
        desc: `A phone app that finds recipes according to preferences for different family members. \
            Users can configure preferences and intolerances of their family members and get recipes that meet all criteria.`,
        technologies: ['Javascript', 'React Native', 'Spoonacular API'],
    },
    {
        id: 'funglish',
        title: 'Funglish',
        image: Funglish,
        video: 'funglish.mp4',
        imageAlt: 'Funglish Web App',
        desc: `A virtual version of the board game "Funglish", a word guessing game based off adjective descriptions used as clues.`,
        technologies: ['Javascript', 'React', 'Google Sheets API'],
    }
]

const Projects = () => {
    const [selectedProject, setSelectedProject] = useState(null);

    return (
        <Box id="projects" component="section" sx={{backgroundColor: '#cac4cf', padding: '4rem', paddingTop: '7rem'}}>
            <p style={{fontSize: '3rem', fontWeight: '800', color: '#32194a', borderBottom: '0.6rem solid rgb(50, 25, 74, 0.5)', width: 'max-content', paddingBottom: '0.5rem', margin: 'auto'}}>Projects</p>
            <div className="Projects__projects">
                {projects.map((proj, index) => 
                    <Card sx={{height: 'max-content'}} key={index}>
                        <CardContent>
                            <Typography sx={{fontSize: '2rem', textAlign: 'center', color: '#32194a'}}>{proj.title}</Typography>
                            <img className="Projects__img" onClick={setSelectedProject.bind({}, proj)} src={proj.image} alt={proj.imageAlt}/>
                            <Typography>{proj.desc}</Typography>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'center', marginBottom: '1rem'}}>
                            <Button onClick={setSelectedProject.bind({}, proj)} variant="contained" size="large">Preview Project</Button>
                        </CardActions>
                    </Card>
                )}
            </div>
            <ProjectModal project={selectedProject} onClose={setSelectedProject.bind({}, null)}/>
        </Box>
    );
};

export default Projects;