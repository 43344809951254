import './Home.scss';

import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import About from './About';
import Game from './Game';
import Navigation from './Navigation';
import Projects from './Projects';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const classes = {
    homeSection: {backgroundColor: '#32194a', display: 'flex', justifyContent: 'center', alignItems: 'center'},
    homeText: {color: 'white', transition: 'color 7s ease-in', position: 'relative', zIndex: '120', userSelect: 'none', margin: '0 1rem'}, // TODO
    letter: {color: '#32194a', fontSize: '10rem', zIndex: '100', margin: '0'}
}

const overlapping = (positionList, left, top) => {
    for (const item of positionList) {
        const matchesX = left >= (item.left - 200) && left <= (item.left + 200);
        const matchesY = top >= (item.top - 200) && top <= (item.top + 200);
        if (matchesX && matchesY) {
            return true;
        }
    }
    return false;
}


const Home = () => {
    const { pathname, hash, key } = useLocation();

    const [word, setWord] = useState('');
    const [board, setBoard] = useState([]);
    const [extraLetters, setExtraLetters] = useState([]);
    const [count, setCount] = useState(0);
    const [wordList, setWordList] = useState(['MOUSE', 'WORLD', 'WOMAN', 'BEACH', 'FRUIT', 'MUSIC', 'TRAIN', 'WATER', 'GREEN', 'APPLE']);
    const [gameDisabled, setGameDisabled] = useState(false);
    const [clicked, setClicked] = useState(false);

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const scroll = () => {
        if (hash === '') window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        else {
            setTimeout(() => {
                const element = document.getElementById(hash.replace('#', ''));
                if (element) element.scrollIntoView({behavior: 'smooth'});
            }, 0);
        }
    }

    const getRandomWord = () => {
        const randomWord = wordList[Math.floor(Math.random() * wordList.length)];
        setWordList(prevValue => prevValue.filter(word => word !== randomWord));
        return randomWord;
    }

    const generateWord = () => {
        const word = getRandomWord();
        if (word) {
            setWord(word);
            setBoard(generateBoard(word));
        }
    };

    const size = useWindowSize();

    useEffect(() => {
        if (count > 1 && board) {
            setGameDisabled(true);
            clearBoard();
        }
        setCount(prevValue => prevValue + 1);
    // eslint-disable-next-line
    }, [size])

    const clearBoard = () => {
        const squares = document.getElementsByClassName('clicked');
        while(squares.length > 0){
            squares[0].parentNode.removeChild(squares[0]);
        }
        setBoard([]);
    };

    const generateBoard = (word) => {
        clearBoard();
        const home = document.getElementsByClassName('home')[0];
        const extraLetters = [];
        while (extraLetters.length < 2) {
            const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)];
            if (!word.includes(randomCharacter) && !extraLetters.includes(randomCharacter)) {
                extraLetters.push(randomCharacter);
            }
        };
        setExtraLetters(extraLetters);
        const positionList = [];
        let overlapAttempts = 0;
        for (const letter of [...word, ...extraLetters]) {
            let left = Math.floor(Math.random() * (home.clientWidth - 200)); // 0 - 9
            let top = Math.floor(Math.random() * (home.clientHeight - 200));
            while (overlapping(positionList, left, top) && overlapAttempts < 25) {
                left = Math.floor(Math.random() * (home.clientWidth - 200));
                top = Math.floor(Math.random() * (home.clientHeight - 200));
                overlapAttempts += 1;
            }
            if (overlapAttempts > 24) {
                setGameDisabled(true);
                break;
            }
            positionList.push({letter, left, top});
        }
        return positionList;
    };

    useEffect(() => {
        if (!isMobile) {
            generateWord();
        }
    // eslint-disable-next-line
    }, [isMobile]);

    useEffect(() => {
        scroll();
    // eslint-disable-next-line
    }, [pathname, hash, key]);

    useEffect(() => {
        scroll();
    // eslint-disable-next-line
    }, []);
    

    const clickHandler = (event) => {
        const home = document.getElementsByClassName('home')[0];
        const b1 = document.createElement("div");
        b1.style.fontSize = '6rem'
        b1.style.textAlign = 'center'
        b1.style.borderRadius = '100%'
        b1.style.margin = '0px';
        const height = Math.min((home.clientHeight - event.pageY) * 2, 150);
        const width = Math.min((home.clientWidth - event.pageX) * 2, 150);
        b1.style.height = `${height}px`;
        b1.style.width = `${width}px`;
        const xPosition = event.pageX - (width/2);
        const yPosition = event.pageY - (height/2);
        b1.style.left = `${xPosition}px`;
        b1.style.top = `${yPosition}px`;
        b1.style.backgroundColor = '#cac4cf';
        b1.style.position = 'absolute';
        b1.className = 'clicked';
        document.getElementsByClassName('home')[0].appendChild(b1);
    };
    

    return (
         <div className="Home" role="main" style={{overflow: 'wrap', scrollBehavior: 'smooth'}}>
            <Box 
                className="home" 
                onMouseDown={board.length && !gameDisabled ? (event) => {setClicked(true); document.body.classList.add('stop-scrolling')} : undefined} 
                onMouseOver={board.length && !gameDisabled && clicked ? clickHandler : undefined} 
                onMouseUp={board.length && !gameDisabled ? () => {setClicked(false); document.body.classList.remove('stop-scrolling')} : undefined} 
                component="section" 
                height="100vh" 
                sx={classes.homeSection}
            >
               <div className="Home__content">
                    <div>
                    <Typography id="hello-text" sx={{...classes.homeText, fontSize: '4rem'}}>Hello, my name is Laila Zoghi.</Typography>
                    {board.length && !gameDisabled ? <Typography id="game-instructions" sx={classes.homeText}>Click & drag around to uncover 7 letters for an unscrambling game later...</Typography> : undefined}
                    </div>
                    <Link style={{marginBottom: '2rem', position: 'relative', zIndex: '120'}} to="#about"><KeyboardDoubleArrowDownIcon fontSize='large' sx={{color: "white"}}/></Link>
               </div>
               {board.map((letter, index) => 
                   <p key={index} style={{...classes.letter, left: letter.left, top: letter.top, position: 'absolute', userSelect: 'none'}}>
                       {letter.letter}
                    </p>
               )}
            </Box>
            {!isMobile ?
                <Navigation/>
            : undefined}
           <About isMobile={isMobile}/>
           <Projects/>
            {isMobile ?
                <Typography color="primary.main" sx={{fontSize: '3rem', fontWeight: '600', textAlign: 'center', margin: '1rem'}}>Visit this site on a desktop computer to play a word game!</Typography>
            :
               <Game word={word} gameDisabled={gameDisabled} resetWord={generateWord} wordsRemaining={wordList.length} clearBoard={() => {clearBoard(); setWord('');}} extraLetters={extraLetters}/>
            }
        </div>
    );
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({width: undefined, height: undefined});
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

export default Home;