// import { createTheme } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import Home from './Home';


const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Home/>
            </BrowserRouter>
        </ThemeProvider>
    );
};

const theme = createTheme({
    palette: {
        primary: {
            light: '#cac4cf',
            main: '#32194a',
            dark: '#221230'
        }
    },
    typography: {
        fontFamily: '"Inter"'
    }
})

export default App;