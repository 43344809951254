// import './Navigation.scss';

import { Box } from "@mui/material";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const classes = {
    nav: {
        height: '5rem', 
        backgroundColor: '#221230', 
        position: 'sticky',
        zIndex: '150',
        top: '0px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '2rem'
    },
    text: {
        margin: '0 1rem',
        fontSize: '1.5rem',
        color: 'white',
        textDecoration: 'none'
    }
}

const Navigation = (props) => {
    const [offset, setOffset] = useState(0);
    const [pageVisible, setPageVisible] = useState('home');

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        const aboutPage = document.getElementById('about').getBoundingClientRect();
        const projectPage = document.getElementById('projects').getBoundingClientRect();
        const gamePage = document.getElementById('game').getBoundingClientRect();
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        const aboutPageVisible = !(aboutPage.bottom < 0 || aboutPage.top + 250 >= viewHeight);
        const projectPageVisible = !(projectPage.bottom < 0 || projectPage.top + 150 >= viewHeight);
        const gamePageVisible = !(gamePage.bottom < 0 || gamePage.top >= viewHeight);
        setPageVisible(gamePageVisible ? 'game' : projectPageVisible ? 'projects' : aboutPageVisible ? 'about' : 'home');
    }, [offset])

    return (
        <Box sx={classes.nav} className="Navigation">
            <Link style={{...classes.text, color: pageVisible === 'home' ? '#9476b0' : 'white'}} to="/">HOME</Link>
            <Link style={{...classes.text, color: pageVisible === 'about' ? '#9476b0' : 'white'}} to="#about">ABOUT</Link>
            <Link style={{...classes.text, color: pageVisible === 'projects' ? '#9476b0' : 'white'}} to="#projects">PROJECTS</Link>
            <Link style={{...classes.text, color: pageVisible === 'game' ? '#9476b0' : 'white'}} to="#game">GAME</Link>
        </Box>
    );
};

export default Navigation;