import './About.scss';

import { Box } from "@mui/material";
import { Link } from 'react-router-dom';
import image from './photo_of_me.jpg';

const About = ({isMobile}) => {
    return (
        <Box id="about" component="section" style={{padding: '4rem', paddingTop: '9rem', marginTop: '-5rem'}}>
            <p style={{fontSize: '3rem', fontWeight: '800', color: '#32194a', borderBottom: '0.6rem solid rgb(50, 25, 74, 0.5)', width: 'max-content', paddingBottom: '0.5rem', margin: 'auto', marginBottom: '2rem'}}>About</p>
            <div className="About__wrapper">
            <img className="About__img" src={image} alt="Laila Zoghi" style={{borderRadius: '100%'}}/>
            <div className="About__desc">
            Hi! My name is <b>Laila</b>, and I'm a front end developer currently working remotely out of Arlington, Virginia.<br/><br/>
            I enjoy designing and developing web applications, mobile apps, and more using Javascript/Typescript and React.<br/><br/>
            When I'm not coding, I spend my time doing craft projects, working on jigsaw puzzles, and finding other ways to be creative.<br/><br/>
            {!isMobile ? <Link style={{textDecoration: 'none', color: 'white', backgroundColor: '#32194a', padding: '0.8rem', borderRadius: '2%', display: 'inline-block'}} to="#projects">See some of my work</Link> : undefined}
            </div>
            </div>
        </Box>
    );
};

export default About;