// @ts-ignore
import { Button, Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { forwardRef } from 'react';
import ReactPlayer from 'react-player';

const Transition = forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectModal = ({project, onClose}) => {
    return (
        project === null ? <></> : 
        // @ts-ignore
        <Dialog open={project !== null} onClose={onClose} fullScreen TransitionComponent={Transition}>
            <DialogTitle sx={{display: 'flex', justifyContent: 'space-between'}}>
                {project.title}
                <Button variant="contained" onClick={onClose}>Close</Button>
            </DialogTitle>
            <DialogContent>
                {project.video ? 
                <ReactPlayer width="100%" height={'95%'} playing url={`videos/${project.video}`} controls style={{marginTop: '1rem'}}/>
                : undefined}
            </DialogContent>
        </Dialog>
    );
};

export default ProjectModal;